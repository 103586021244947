<template>
  <div class="noticeWrap" v-loading="loadShow">
    <div class="mainCom w1200 clearfix">
      <div class="mainComL">
        <menuWX></menuWX>
      </div>
      <div class="mainComR" :style="{ backgroundImage: 'url(' + bg1 + ')' }">
        <img class="noticeIcon" :src="noticeIcon" alt="">
        <div class="noticeBar mb20" :style="{ backgroundImage: 'url(' + bg2 + ')' }">
          <div class="noticMainBar">
            <div class="noticMain">
              <div class="titleWrap mb20">
                <img class="title" :src="title" alt="">
                <span class="back" @click="goBack">返回上级</span>
              </div>
              <div class="desWrap mb20">
                <div class="desTitle"><img class="title" :src="ruleTitle" alt=""></div>
                <div class="rules">
                  <p><span class="num">1.</span><span class="txt1">您可以通过公告系统送出您的愿望或祝福。公告发布成功后将通过游戏客户端展示。</span></p>
                  <p><span class="num">2.</span><span class="txt1">公告发布内容需遵守相关法律法规，不得涉及违规内容以及时政信息。</span></p>
                  <p><span class="num">3.</span><span
                      class="txt1">用户单日充值满888白金币可发布特权公告，特权公告依据用户出价决定展示优先级。默认起价为5白金币。</span></p>
                  <p><span class="num">4.</span><span class="txt1">用户可通过公告系统向指定用户赠送鲜花，每朵鲜花定价为2白金币。获赠鲜花的用户将获得魅力值。&nbsp;
                      &nbsp;（1朵鲜花增加1点魅力值）</span></p>
                  <p><span class="num">5.</span><span
                      class="txt1">每周结算时候登顶充值排行、各类公告排行榜、魅力排行榜以及送花排行榜的玩家一周内可独享专属身份标识。</span></p>
                  <p><span class="num">6.</span><span class="txt1">普通公告价格为888泡点/条。</span></p>
                </div>
              </div>
              <div class="selectWrap">
                <img class="hand" :src="hand" alt="">
                <span class="txt">选择公告</span>
                <div class="noticType">
                  <img v-for="(item, index) in icons" :key="index" :src="item.url" :alt="item.name"
                    @click="changeNoticType(item, index)">
                </div>
              </div>
              <div class="configWrap mb20">
                <el-form ref="form" :model="noticForm">
                  <div class="config">
                    <el-form-item label="类型" class="w150">
                      <img class="typeImg" :src="icons[iconsActive].url"
                        :alt="icons[(parseInt(noticForm.type) - 1)].name">
                    </el-form-item>
                    <el-form-item label="颜色">
                      <el-select v-model="noticForm.color" class="w70">
                        <el-option v-for="(item, index) in colorList" :key="index" :label="item.label" :value="item.value">
                          <span :style="{ color: item.value }">{{ item.label }}</span>
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="价格">
                      <el-input v-if="goldi_index != 0" v-model="noticForm.price" class="w170 price"
                        :disabled="noticForm.type != 4" placeholder="请输入单价（5白金币起）"></el-input>
                        <el-input v-else v-model="ordinary" class="w170 price"
                        :disabled="noticForm.type != 4" placeholder="请输入单价（5白金币起）"></el-input>
                    </el-form-item>
                    <el-form-item label="数量">
                      <el-input v-model="noticForm.num" class="w70" placeholder="1"></el-input>
                    </el-form-item>
                    <el-form-item label="身份标识">
                      <el-select v-model="noticForm.identityType" class="w94">
                        <el-option v-for="(item, index) in idFlagList" :key="index" :label="item.label"
                          :value="item.value"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="赠送鲜花" class="w150">
                      <el-switch v-model="isGiftFlower"></el-switch>
                    </el-form-item>
                    <el-form-item label="品种">
                      <el-select v-model="noticForm.flowerType" class="w70">
                        <el-option v-for="(item, index) in varietyList" :key="index" :label="item.label"
                          :value="item.value"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="送花给">
                      <el-input v-model="noticForm.targetUsername" class="w150 giveFlowerTo"
                        placeholder="请输入受赠人昵称"></el-input>
                    </el-form-item>
                    <el-form-item label="数量">
                      <el-select v-model="noticForm.flowerNum" class="w70">
                        <el-option v-for="(item, index) in num2List" :key="index" :label="item.label"
                          :value="item.value"></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div class="tip">
                    <img :src="tip" alt="">
                  </div>
                  <div class="selectBgWrap">
                    <div class="title">背景卡选择</div>
                    <el-form-item class="bgWrap">
                      <el-radio-group v-model="noticForm.bgId">
                        <div class="item" v-for="(item, index) in bgList" :key="index">
                          <div class="pic"><img :src="item.picUrl" alt=""></div>
                          <el-radio :label="item.type">{{ item.name }}</el-radio>
                        </div>
                      </el-radio-group>
                    </el-form-item>
                  </div>
                  <div class="noticeConWrap">
                    <div class="title">内容</div>
                    <div class="noticConBar">
                      <el-form-item class="noticeCon">
                        <div id="noticeCon" style=" white-space: pre-wrap;" class="noticeTxt" ref="noticeCon" v-html="noticeCon" contenteditable="true"
                          @compositionend="compositionendFn" @input="inputTxt" @keydown="keyDownFn"></div>
                        <span class="txtCount">{{ txtTotal }} / 28</span>
                      </el-form-item>
                      <el-form-item class="submitWrap">
                        <div><img :src="submitBtn" @click="sendNotice"></div>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="calcBalance">
                    <div class="title">余额&消费明细</div>
                    <div class="cons">
                      <div v-if="goldi_index == 0" class="item">本次消费：{{ isGiftFlower == true ? spendingAmount.B + '白金币'
                        + ',' + spendingAmount.P + '泡点' : spendingAmount + '泡点' }}</div>
                      <div v-else class="item">本次消费：{{ spendingAmount }}白金币</div>
                      <div class="item">白金币余额：<template v-if="userInfo.wallteInfo">{{ userInfo.wallteInfo.chargeMoney
                          }}</template>
                      </div>
                      <div class="item">绑定白金币余额：<template v-if="userInfo.wallteInfo">{{ userInfo.wallteInfo.giftMoney
                          }}</template></div>
                      <div class="item">泡点余额：<template v-if="userInfo.wallteInfo">{{ userInfo.gameDataInfo.onlineTime
                          }}</template></div>
                    </div>
                  </div>
                </el-form>
              </div>
              <div class="expressionWrap">
                <img v-for="(item, index) in emotionList" :key="index" :src="emotionUrl + item.name" @click="addEmotion(item)">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import menuWX from '@/components/Menu'
import downItemWX from '@/views/downLoad/item'
import { publicUrl } from '@/common/publicUrl.js'
import axios from "axios";
import Cookies from "js-cookie";
export default {
  name: 'notice',
  components: {
    menuWX,
    downItemWX,
  },
  data() {
    return {
      loadShow: false,
      bg1: require('@/assets/imgs/notice/bg1.png'),
      bg2: require('@/assets/imgs/notice/bg2.png'),
      bg3: require('@/assets/imgs/notice/bg3.png'),
      title: require('@/assets/imgs/notice/title.png'),
      ruleTitle: require('@/assets/imgs/notice/rule.png'),
      noticeIcon: require('@/assets/imgs/notice/noticeIcon.png'),
      hand: require('@/assets/imgs/notice/hand.png'),
      iconsActive: 3,
      icons: [
        { name: '普通公告', type: '1', price: '888', url: require('@/assets/imgs/notice/icon3.png') },
        { name: '超级公告', type: '2', price: '2', url: require('@/assets/imgs/notice/icon2.png') },
        { name: '霸屏公告', type: '3', price: '3', url: require('@/assets/imgs/notice/icon1.png') },
        { name: '特权公告', type: '4', price: '', url: require('@/assets/imgs/notice/icon0.png') },
      ],
      tip: require('@/assets/imgs/notice/tip.png'),
      submitBtn: require('@/assets/imgs/notice/btn.png'),
      noticeCon: '',
      txtTotal: 0,
      isGiftFlower: false,
      noticForm: {
        type: 4,               // 公告类型:1:普通公告，2:超级公告,3:霸屏公告，4:特权公告
        color: '#32CD32',      // 公告颜色
        price: '',             // 公告价格
        num: '',               // 数量
        identityType: 0,       // 身份标识类型
        isGiftFlower: false,   // 是否送花,0:不送，1：赠送
        flowerType: '玫瑰',    // 鲜花种类
        targetUsername: '',    // 送花给谁
        flowerNum: 1,          // 鲜花数量
        bgId: '1',             // 背景表情id
        content: '',           // 公告内容
        platForm: 'web',
        userId: Cookies.get('wx_userId'),
      },

      /*
      * 公告数量最多100条
      * A、公告类型：公告类型分为普通公告、超级公告、霸屏公告、特权公告
      普通公告展示时间为5秒，价格为1绑定白金币
      超级公告展示时间为5秒  价格为2绑定白金币
      霸屏公告展示时间为10秒  价格为3绑定白金币
      特权公告展示时间为10秒  用户可自定义价格（起价5绑定白金币），并依据单条价格高低决定展示优先级。
      * 只有特权是自己输入金额
      * 其他都是固定的金额
      * 表情做多5个
      * 剩余文字+标点符号28个
      * */
      colorList: [
        { label: '深兰花', value: '#9932CD' },
        { label: '金色', value: '#CD7F32' },
        { label: '石灰绿', value: '#32CD32' },
        { label: '橙色', value: '#FF7F00' },
        { label: '橙红色', value: '#FF2400' },
        { label: '石板蓝', value: '#007FFF' },
        { label: '浓深棕', value: '#5C4033' },
        { label: '麦黄色', value: '#D8D8BF' },
        { label: '黑色', value: '#000000' },
      ],
      idFlagList: [
        { label: '无', value: 0 },
        { label: 'VIP', value: 1 },
        { label: '充值名人', value: 2 },
        { label: '魅力名人', value: 3 },
        { label: '送花名人', value: 4 },
        { label: '特权名人', value: 5 },
        { label: '霸屏名人', value: 6 },
        { label: '超级名人', value: 7 },
      ],
      num2List: [
        { label: '1朵', value: 1 },
        { label: '11朵', value: 11 },
        { label: '99朵', value: 99 },
        { label: '520朵', value: 520 },
        { label: '1314朵', value: 1314 },
      ],
      varietyList: [
        { label: '玫瑰', value: '玫瑰' },
        { label: '牡丹', value: '牡丹' },
        { label: '百合', value: '百合' },
        { label: '郁金香', value: '郁金香' },
        { label: '满天星', value: '满天星' },
      ],
      bgList: [
        { name: '告白', type: '1', picUrl: require('@/assets/imgs/notice/bg0.gif') },
        { name: '祝福', type: '2', picUrl: require('@/assets/imgs/notice/bg1.gif') },
        { name: '热恋', type: '3', picUrl: require('@/assets/imgs/notice/bg2.gif') },
        { name: '愤怒', type: '4', picUrl: require('@/assets/imgs/notice/bg3.gif') },
        { name: '召唤', type: '5', picUrl: require('@/assets/imgs/notice/bg4.gif') },
        { name: '春节', type: '6', picUrl: require('@/assets/imgs/notice/bg5.gif') },
        { name: '怨念', type: '7', picUrl: require('@/assets/imgs/notice/bg6.gif') },
        { name: '祈愿', type: '8', picUrl: require('@/assets/imgs/notice/bg7.gif') },
        { name: '生日', type: '9', picUrl: require('@/assets/imgs/notice/bg8.gif') },
      ],
      emotionUrl: publicUrl.emotion,
      emotionList: [],
      userInfo: {},

      //选择公告索引_是否使用白金币 gold
      //选择公告索引_是否使用泡点
      goldi_index: 3,
      ordinary:'888泡点'
    }
  },
  created() {
    this.getEmotionList()
    //getC($('.editdiv'))
  },
  mounted() {
    document.documentElement.scrollTop = 0
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
  },
  methods: {
    getC(el) {
      el.focus();
      var range = document.createRange();
      range.selectNodeContents(el);
      range.collapse(false);
      var sel = window.getSelection();
      //判断光标位置，如不需要可删除
      if (sel.anchorOffset != 0) {
        return;
      };
      sel.removeAllRanges();
      sel.addRange(range);
    },

    compositionendFn(e) {
      this.inputFn()
    },
    inputTxt(e) {
      if (e.inputType == 'insertText') {
        this.inputFn()
      }
    },
    keyDownFn(event) {
      console.log();
      if (event.keyCode == 13) {
        event.preventDefault();
      }
    },
    inputFn() {
      let conticeText = document.getElementById('noticeCon').innerText
      let conticeHtml = document.getElementById('noticeCon').innerHTML
      
      if (conticeText.length > 28) {
        this.txtTotal = 28
        this.$message.error('公告最多输入28个字符')
        document.getElementById('noticeCon').innerHTML = conticeHtml.substr(0, conticeHtml.length - (conticeText.length - 28))
      } else {
        this.txtTotal = conticeText.length
        document.getElementById('noticeCon').innerHTML = conticeHtml
      }
      this.getC(document.getElementById('noticeCon'))
    },
    addEmotion(item) {
      let html1 = document.getElementById('noticeCon').innerHTML;
      let regex = /<img[^>]*>/g;                          // 匹配所有<img>标签
      let matches = []
      if (html1.match(regex)) {
        matches = html1.match(regex);                   // 匹配出所有的图片
      }
      if (matches.length < 5) {
        document.getElementById('noticeCon').innerHTML = document.getElementById('noticeCon').innerHTML + '<img src="' + this.emotionUrl + item.name + '">'
      } else {
        this.$message.error('最多输入5个表情')
      }
    },
    getEmotionList() {
      this.loadShow = true
      axios({
        method: 'post',
        url: '/notice/getEmotionList',
        headers: {
          token: Cookies.get('wx_token'),
        },
        data: {
          platForm: 'web',
          userId: Cookies.get('wx_userId'),
        },
      }).then((res) => {
        this.loadShow = false
        if (res.data.state == 200) {
          this.emotionList = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        this.$message.error(err.message)
      })
    },      // 获取公告表情列表
    sendNotice() {       // 发布公告
      if (this.noticForm.type == 4 && this.noticForm.price == '') {
        this.$message.error('请输入价格')
        return false
      } else if (this.noticForm.type == 4 && (isNaN(this.noticForm.price) || this.noticForm.price < 5 || !(/^[1-9][0-9]*$/.test(this.noticForm.price)))) {
        this.$message.error('价格必须是大于等于5的整数')
        return false
      } else if (this.noticForm.num == '' || this.noticForm.num == 0) {
        this.$message.error('请输入数量')
        return false
      } else if (isNaN(this.noticForm.num) || !(/^[1-9][0-9]*$/.test(this.noticForm.price))) {
        this.$message.error('数量必须是一个整数')
        return false
      } else if (this.isGiftFlower && this.noticForm.targetUsername == '') {
        this.$message.error('请输入鲜花受赠人昵称')
        return false
      } else if (document.getElementById('noticeCon').innerHTML == '') {
        this.$message.error('请输入公告内容')
        return false
      }
      this.noticForm.isGiftFlower = this.formatFlower(this.isGiftFlower)
      this.noticForm.content = document.getElementById('noticeCon').innerHTML.replace(/<img src=\\/g, '<imgsrc=').replace(/&amp;nbsp;/g, " ").replace(/&amp;/g, ' ')
      axios({
        method: 'post',
        url: '/notice/sendNotice',
        headers: {
          token: Cookies.get('wx_token'),
        },
        data: this.noticForm,
      }).then((res) => {
        this.loadShow = false
        if (res.data.state == 200) {
          // 刷新用户信息
          this.getGold()
          this.$message.success(res.data.message)
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        this.$message.error(err.message)
      })
    },

    changeNoticType(item, index) {
      this.goldi_index = index;
      this.iconsActive = index
      this.noticForm.type = item.type
      this.noticForm.price = item.price
    },    // 切换公告类型
    goBack() {
      this.$router.go(-1)
    },
    formatFlower(value) {
      return value ? 1 : 0
    },      // 格式化是否赠送鲜花

    // 获取用户白金币
    getGold() {
      axios({
        method: 'post',
        url: '/user/getMemberInfo',
        headers: {
          token: Cookies.get('wx_token'),
        },
        data: {
          userId: Cookies.get('wx_userId'),      // userId
          platForm: 'web',
        },
      }).then((res) => {
        if (res.data.state == 200) {
          this.userInfo = res.data.data;
          this.resetSetItem('userInfo', JSON.stringify(res.data.data))
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        this.$message.error(err.message)
      })
    }
  },
  computed: {
    spendingAmount() {
      let total = 0
      let num = 0     // 公告数量
      let price = 1   // 公告金额
      // console.log(this.noticForm.isGiftFlower);
      if (this.noticForm.price != '' && this.noticForm.num != '') {
        num = this.noticForm.num
        price = this.noticForm.price
      }
      if (this.isGiftFlower && this.goldi_index == 0) {
        total = {
          B: parseInt(this.noticForm.flowerNum) * 2,
          P: num * price
        }
      } else if (this.isGiftFlower) {
        total = num * price + parseInt(this.noticForm.flowerNum) * 2
      } else {
        total = num * price
      }
      return total
    },
  }
}
</script>

<style lang="less">
.noticeWrap {
  .configWrap {
    .config {
      .el-form-item {
        margin-bottom: 14px;
        margin-right: 16px;
        float: left;
        display: flex;
        align-items: center;

        .el-form-item__label {
          padding-right: 8px;
          font-size: 20px;
          font-weight: 700;
          color: #333333;
          height: 32px;
          line-height: 32px;
          white-space: nowrap;
        }

        .el-form-item__content {
          height: 32px;
          line-height: 32px;

          .el-input__inner {
            padding: 0 20px 0 6px;
            height: 32px;
            line-height: 32px;
            box-sizing: border-box;
            border: 1px solid #FFA060;
          }

          .el-input__icon {
            color: #FFA060;
            height: 32px;
            line-height: 32px;
          }

          .el-input__suffix {
            right: 0;
          }

          .giveFlowerTo {
            .el-input__inner {
              padding: 0 6px;
            }
          }

          .price {
            .el-input__inner {
              padding: 0 6px;
              color: #333333;
            }
          }
        }
      }
    }

    .selectBgWrap {
      .el-form-item {
        .el-radio-group {
          overflow: hidden;

          .el-radio__inner {
            width: 20px;
            height: 20px;
            border: 1px solid #FFA060;
            border-radius: 4px;
          }

          .el-radio__label {
            padding-left: 5px;
            font-size: 18px;
            line-height: 32px;
          }

          .el-radio__input.is-checked {
            .el-radio__inner {
              background: none;

              &:after {
                width: 13px;
                height: 4px;
                background: none;
                content: "";
                position: absolute;
                left: 2px;
                top: 4px;
                border-top: 2px solid #FFA060;
                border-right: 2px solid #FFA060;
                border-radius: 0;
                transform: rotate(125deg);
              }
            }
          }

          .el-radio__input.is-checked+.el-radio__label {
            color: #FFA060;
          }
        }
      }
    }

    .noticeConWrap {
      .el-textarea__inner {
        height: 110px;
        border: 1px solid #FFA060;
      }
    }

    .calcBalance {
      padding-top: 20px;

      .title {
        margin-bottom: 10px;
        font-size: 20px;
        font-weight: 700;
      }

      .cons {
        overflow: hidden;

        .item {
          float: left;
          width: 25%;
        }
      }
    }
  }
}
</style>
<style lang="less" scoped>
.noticeWrap {
  .mainComR {
    position: relative;
    z-index: 1;
    padding-top: 95px;
    background-repeat: no-repeat;
    background-size: 100% auto;

    .noticeIcon {
      position: absolute;
      left: -25px;
      top: -21px;
      z-index: 2;
    }

    .noticeBar {
      padding-bottom: 80px;
      box-sizing: border-box;
      background-repeat: no-repeat;
      background-position: bottom;
      background-size: 100% auto;

      .noticMainBar {
        margin: 0 3px 0 2px;
        padding: 0 20px;
        background: linear-gradient(to bottom, #faf4e9, #f9f4e6);
        border-left: 5px solid #fe9f5e;
        border-right: 5px solid #fe9f5e;

        .noticMain {
          transform: translateY(-70px);

          .titleWrap {
            text-align: center;
            position: relative;
            z-index: 1;
            margin-bottom: 22px;

            .back {
              position: absolute;
              right: 40px;
              top: 10px;
              text-decoration: underline;
              font-size: 20px;
              cursor: pointer;
            }
          }

          .desWrap {
            padding: 30px 110px;
            line-height: 24px;
            background-color: rgba(255, 255, 255, 0.5);
            border-radius: 10px;

            .desTitle {
              text-align: center;
            }

            .rules {
              p {
                display: flex;
                color: #FF7900;

                span {
                  font-size: 14px;
                }

                .num {
                  display: inline-block;
                  min-width: 20px;
                }
              }
            }
          }

          .selectWrap {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            .hand {
              height: 37px;
            }

            .txt {
              margin-left: 7px;
              padding: 0 3px;
              height: 36px;
              font-size: 26px;
              font-weight: 600;
              color: #333333;
              line-height: 30px;
              position: relative;
              z-index: 0;

              &:after {
                position: absolute;
                left: 0;
                bottom: 0;
                z-index: -1;
                display: inline-block;
                content: '';
                width: 100%;
                height: 18px;
                background: #FFA160;
                border-radius: 50%;
              }
            }

            .noticType {
              overflow: hidden;

              img {
                float: right;
                margin-left: 28px;

                &:hover {
                  opacity: 0.7;
                  cursor: pointer;
                }
              }
            }
          }

          .configWrap {
            padding: 24px 0 24px 20px;
            border: 2px dashed #FFA160;

            .w70 {
              width: 70px;
            }

            .w94 {
              width: 94px;
            }

            .w150 {
              width: 150px;
            }

            .w170 {
              width: 170px;
            }

            .w180 {
              width: 180px;
            }

            .config {
              overflow: hidden;

              .typeImg {
                width: 100px;
              }
            }

            .tip {
              margin-bottom: 20px;
              margin-right: 20px;
              height: 32px;
              line-height: 32px;
              text-align: center;
              border: 1px solid #FFA060;
              border-radius: 4px;
            }

            .selectBgWrap {
              .title {
                margin-bottom: 10px;
                font-size: 20px;
                font-weight: 700;
              }

              .bgWrap {
                .item {
                  float: left;
                  margin-right: 16px;
                  margin-bottom: 10px;
                  display: flex;
                  align-items: center;

                  .pic {
                    margin-right: 10px;
                    width: 200px;
                    height: 30px;
                    border: 1px solid #FFA060;
                    border-radius: 4px;

                    img {
                      width: 100%;
                      height: 30px;
                    }
                  }
                }

              }
            }
          }

          .noticeConWrap {
            .title {
              margin-bottom: 10px;
              font-size: 20px;
              font-weight: 700;
            }

            .noticConBar {
              display: flex;

              .noticeCon {
                margin-bottom: 0;
                flex: 1;
                position: relative;
                z-index: 1;

                .noticeTxt {
                  padding: 0 10px;
                  height: 120px;
                  background: #ffffff;
                  border: 1px solid #ffa160;
                  border-radius: 4px;
                  box-sizing: border-box;
                  outline: 0;
                }

                .txtCount {
                  position: absolute;
                  right: 10px;
                  bottom: -5px;
                  color: #aaaaaa;
                }
              }

              .submitWrap {
                margin-left: 20px;
                margin-bottom: 0;

                img {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
}

.expressionWrap {
  padding: 5px;
  width: 100%;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  box-sizing: border-box;

  img {
    margin: 5px;
    height: 22px;
  }
}
</style>
